import * as React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import favicon16 from "../fav_icons/favicon-16x16.png"
import favicon32 from "../fav_icons/favicon-32x32.png"
import favicon72 from "../fav_icons/favicon-72x72.png"

import "modern-normalize"
import "../styles/normalize"

import Header from "../components/Header"

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: data.site.siteMetadata.description },
            { name: "keywords", content: data.site.siteMetadata.keywords }
          ]}
          link={[
            { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
            { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` }
          ]}
        >
          {/* <!-- Global site tag (gtag.js) - Google Ads: 698521254 -->  */}

          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-698521254"></script>
          <script>
            {`
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date()); gtag('config', 'AW-698521254');
             `}
          </script>
        </Helmet>
        {/* <Header title={data.site.siteMetadata.title} /> */}
        {children}
      </>
    )}
  />
)

export default IndexLayout
